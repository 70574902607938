import MainHeader from '@/components/main-header/MainHeader'
import Drawer from '@/components/drawers/Drawer'
import LanguageDrawer from '@/components/drawers/LanguageDrawer'
import MainFooter from '@/components/main-footer/MainFooter'

import StorageHelper from '@/helpers/localStorageHelper'
import ApiBase from '@/api/base'
import ApiTranslations from '@/api/translations'
import ApiEmails from '@/api/emails'

export default {
	name: 'MainContainer',
	components: {
		MainHeader,
		Drawer,
		LanguageDrawer,
		MainFooter
	},
	metaInfo () {
		return {
			titleTemplate: '%s | 1tn'
		}
	},
	created () {
		ApiEmails.getAll(this)
	},
	methods: {
		changeLocale (e) {
			StorageHelper.set('language', e)
			ApiBase.setToStore(null, 'setCurrentLang', e)
			ApiTranslations.getByCurrentLanguage(this)
		}
	}
}
