import ContactSalesDialog from '@/components/dialogs/contact-sales-dialog/ContactSalesDialog'
import ApiSocialMedias from '@/api/social-medias.js'

export default {
	name: 'MainFooter',
	components: {
		ContactSalesDialog
	},
	created () {
		ApiSocialMedias.getAll(this)
	},
	computed: {
		socialMediasList() {
			return this.state.socialMediasList.filter(i => i.visible === true)
		},
		companyLinks () {
			return [
				{
					title: this.trans('footer_page_about'),
					to: 'AboutUs'
				},
				{
					title: this.trans('footer_page_career'),
					to: 'Careers'
				},
				{
					title: this.trans('footer_page_blog'),
					to: 'Blog'
				}
			]
		},
		resourcesLinks () {
			return [
				{
					title: this.trans('footer_page_faq'),
					to: 'Help'
				},
				{
					title: this.trans('footer_page_terms_of_service'),
					to: 'TermsOfService'
				},
				{
					title: this.trans('footer_page_privacy_policy'),
					to: 'PrivacyPolicy'
				},
				{
					title: this.trans('footer_page_aml_policy'),
					to: 'AmlPolicy'
				}
			]
		},
		productLinks () {
			return [
				{
					title: this.trans('footer_page_payment_gateway'),
					to: 'Home'
				},
				{
					title: this.trans('footer_page_wallet'),
					to: 'Wallet'
				},
				{
					title: this.trans('footer_page_white_label'),
					to: 'WhiteLabel'
				},
				{
					title: this.trans('footer_page_custody_solution'),
					to: 'CustodySolution'
				},
				{
					title: this.trans('footer_page_otc_platform'),
					to: 'PremiumExchange'
				}
			]
		},
	}
}
