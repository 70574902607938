import Drawer from "@/components/drawers/Drawer";
import Products from "@/components/main-header/Products";
import ContactSalesDialog from "@/components/dialogs/contact-sales-dialog/ContactSalesDialog";
import CompanyElement from "@/components/main-header/CompanyElement";
import AcceptCoinsDialog from "@/components/dialogs/accept-coins-dialog/AcceptCoinsDialog";

export default {
	name: "MainHeader",
	components: {
		Drawer,
		Products,
		ContactSalesDialog,
		CompanyElement,
		AcceptCoinsDialog,
	},
	data: () => ({
		drawerOpened: false,
	}),
	computed: {
		href: {
			get() {
				return window.location.href;
			},
			set(value) {
				window.location.href = value;
			},
		},
		menuItems() {
			return [
				{
					title: this.trans("header_item_blog"),
					to: "Blog",
				},
				{
					title: this.trans("header_item_become_partner"),
					to: "BecomePartner",
				},
			];
		},
	},
	methods: {
		showDrawerMenu() {
			this.$root.$emit("showDrawer");
			this.$root.$emit("closeProducts");
			this.drawerOpened = !this.drawerOpened;
		},
		changeLocale(code) {
			this.$emit("changeLocale", code);
		},
		goToPage(name) {
			if (this.$route.name !== name) {
				this.$router.push({
					name: name,
				});
			}
		},
	},
	mounted() {
		this.$root.$on("closeBurgerMenu", () => {
			this.drawerOpened = false;
		});
	},
};
