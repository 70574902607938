<template>
	<v-navigation-drawer
		style="z-index: 102;"
		class="elevation-0"
		v-if="$vuetify.breakpoint.smAndDown"
		height="100vh"
		width="100%"
		absolute
		right
		v-model="langDrawer"
	>
	<div class="content mt-6">
		<div
			class="d-flex align-center mb-13"
			@click="closeLanguages"
		>
		<v-icon class="icon" color="mainColor">
			mdi-menu-down
		</v-icon>
			<span class="text-body-2">
				Change language
			</span>
		</div>
		<div>
			<span
				v-for="(language, index) in state.appLangs"
				:key="index"
				@click="changeLocale(language.code)"
				:class="[
					'language d-block text-body-2',
					state.currentLang === language.code ? 'mainColor--text font-weight-bold' : 'grayText--text'
				]"
			>
				{{ language.title }}
			</span>
		</div>
	</div>
	</v-navigation-drawer>
</template>

<script>
export default {
	name: 'LanguageDrawer',
	data: () => ({
		langDrawer: false
	}),
	methods: {
		closeLanguages () {
			this.langDrawer = false
			this.$root.$emit('closeBurgerMenu')
		},
		changeLocale (code) {
			this.$emit('changeLocale', code)
			this.closeLanguages()
		}
	},
	mounted() {
		this.$root.$on('showLanguageDrawer', () => {
			this.langDrawer = !this.langDrawer
		})
	}
}
</script>

<style lang="scss" scoped>
.language {
	cursor: pointer;
	transition: 0.3s;

	&:not(:last-child) {
		padding-bottom: 30px;
	}

	&:hover {
		color: var(--v-mainColor-base) !important;
	}
}
.icon {
	transform: rotate(90deg) !important;
	width: 10px;
	height: 6px;
	margin-right: 8px;
}
</style>
